html, body, h1, h2, h3, h4, h5, h6, p, ol, ul, li, a, blockquote, pre, hr, figure, fieldset, legend, textarea, th, td {
  font-family: Work Sans, sans-serif;
}

body {
  -ms-animation: fadein 1s;
  animation: 1s fadein;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.custom-container {
  max-width: 1226px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.nav-link.active {
  border-bottom: 3px solid #fff;
  font-weight: bold;
}

header {
  padding-top: 60px;
}

.logo {
  width: 175px;
}

#navbar {
  z-index: 999;
  height: 80px;
  background: #081e58;
  align-items: center;
  padding-bottom: 0;
  transition: background-color .3s;
  display: flex;
}

#navbar header, #navbar.transparent header {
  padding-top: 0;
}

#nav-desktop a {
  margin-right: 25px;
  font-size: 15px;
  font-weight: 700;
  line-height: 40px;
}

.text-primary {
  color: #081e58 !important;
}

.bg-gradient {
  background: linear-gradient(257.98deg, #0047ff 9.06%, #030303 101.32%) !important;
}

.hero-bg {
  background-image: url("hero-bg.0729c87a.jpg");
  background-position: center;
  background-size: cover;
}

.nav-link.active {
  border-bottom: 3px solid #fff;
  font-weight: bold;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.slide-in-from-left {
  animation: .5s ease-in-out slideInFromLeft;
}

@keyframes slideOutToLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.slide-out-to-left {
  animation: .5s ease-in-out slideOutToLeft;
}

.mobile-nav {
  z-index: 99999999;
  width: 100%;
  height: 100%;
  background-color: #081e58;
  position: fixed;
  top: 0;
  left: 0;
}

.text-small {
  font-size: .8rem;
}

.transparent {
  background-color: #0000 !important;
  border: none !important;
}

.bg-primary {
  background-color: #081e58 !important;
}

.bg-black {
  background-color: #000 !important;
}

.bg-light {
  background-color: #f2f2f2 !important;
}

.btn.bg-black.btn-dark:hover {
  color: #000 !important;
  background-color: #fff !important;
}

.btn.bg-black.btn-dark:active {
  transform: translateY(1px);
  color: #000 !important;
  background-color: #fff !important;
}

.btn-primary {
  text-align: center;
  letter-spacing: -.025em;
  color: #fff;
  height: 43px;
  min-width: 236px;
  background: #000;
  border: 0;
  border-radius: 100px;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 43px;
}

.btn-primary:hover, .btn:first-child:active, .btn:focus-visible, .btn:first-child:active:focus-visible {
  filter: drop-shadow(0 4px 4px #00000040);
  color: #030303;
  box-shadow: none;
  background-color: #fff;
  border: 0;
}

.main-banner {
  height: 563px;
}

.main-banner h1 {
  margin-top: 30px;
  font-weight: 800;
}

.main-banner .object-1 {
  width: 122px;
  z-index: 1;
  bottom: 32%;
  right: 0;
}

.main-banner .object-2 {
  width: 171px;
  height: auto;
  top: 210px;
  bottom: 0;
}

.home-page .start-audit-section {
  height: 563px;
}

.home-page .start-audit-section .wrapper {
  max-width: 984px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.home-page .start-audit-section .smart-grid {
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.home-page .start-audit-section .smart-grid .book-img img {
  max-width: 353px;
  transform: translate(146px, 122px);
}

.home-page .start-audit-section .object-1 {
  width: 200px;
  position: absolute;
  transform: translate(42%, -51%);
}

.home-page .start-audit-section .start-audit {
  position: relative;
  top: 10px;
}

.home-page .start-audit-section .smart-grid p {
  letter-spacing: -.025em;
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  line-height: 34px;
}

.home-page .smart-thing-section {
  min-height: 446px;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.home-page .smart-thing-section h2 {
  letter-spacing: -.025em;
  color: #00237d;
  max-width: 330px;
  font-size: 48px;
  font-weight: 800;
  line-height: 54px;
  transform: translate(8px);
}

.home-page .smart-thing-section p {
  letter-spacing: -.025em;
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.home-page .smart-thing-section .about-btn {
  transform: translate(2px, 14px);
}

.home-page .smart-thing-section .smart-grid {
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.home-page .three-step-smart {
  height: 497px;
  background: url("bg-section-2.a250f108.jpg") center / cover no-repeat;
}

.home-page .three-step-smart .wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.home-page .three-step-smart .wrapper h4 {
  text-align: center;
  letter-spacing: -.025em;
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  line-height: 64px;
  transform: translate(0, -25px);
}

.home-page .three-step-smart .wrapper .smart-img-grid {
  grid-template-columns: repeat(3, minmax(120px, 120px));
  justify-content: space-between;
  display: grid;
}

.home-page .three-step-smart .wrapper .smart-img-grid p {
  text-align: center;
  letter-spacing: -.025em;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 54px;
}

.home-page .three-step-smart .wrapper .smart-img-grid img {
  width: 120px;
}

.home-page .three-step-smart .wrapper .smart-img-grid .inden {
  transform: translate(5px, -11px);
}

.home-page .three-step-smart .wrapper .smart-img-grid .inden p {
  transform: translate(-12px, 4px);
}

.home-page .three-step-smart .wrapper .smart-img-grid .remedi {
  transform: translate(0, -11px);
}

.home-page .three-step-smart .wrapper .smart-img-grid .remedi p {
  transform: translate(-12px, 4px);
}

.home-page .three-step-smart .wrapper .smart-img-grid .verif {
  transform: translate(12px, -9px);
}

.home-page .three-step-smart .wrapper .smart-img-grid .verif p {
  transform: translate(-8px, 4px);
}

.home-page .three-step-smart.work-with-audit {
  background: none;
}

.home-page .three-step-smart.work-with-audit .wrapper {
  max-width: 1100px;
}

.home-page .three-step-smart.work-with-audit h4 {
  color: #1d1d1d;
}

.home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  justify-content: space-between;
  display: grid;
}

.home-page .three-step-smart.work-with-audit .wrapper h4 {
  transform: translate(0, -7px);
}

.home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid p {
  text-align: center;
  letter-spacing: -.025em;
  color: #081e58;
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 54px;
}

.home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .inden p {
  transform: translate(-102px, 1px);
}

.home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .remedi p {
  transform: translate(-1px, 10px);
}

.home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .verif p {
  transform: translate(41px, 2px);
}

.home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid img {
  width: 140px;
}

.home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .inden {
  transform: translate(39px, 13px);
}

.home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .remedi {
  transform: translate(0, 4px);
}

.home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .verif {
  transform: translate(-37px, 9px);
}

.home-page .three-step-smart.work-with-audit .audit-btn {
  transform: translate(0, -26px);
}

.seen-in-section .seen-grid-1 {
  grid-gap: 40px;
  grid-template-rows: 80px;
  grid-auto-flow: column;
  place-content: flex-start;
  align-items: center;
  display: grid;
}

.seen-in-section h2 {
  letter-spacing: -.025em;
  color: #081e58;
  margin-top: 70px;
  margin-bottom: 13px;
  font-size: 28px;
  font-weight: 700;
  line-height: 60px;
}

.testiMonial {
  margin-top: 72px;
}

.testiMonial .slide-grid {
  height: 540px;
  grid-template-columns: 35% 1fr;
  display: grid;
  position: relative;
}

.testiMonial .slide-grid .gray-bg {
  background: #f2f2f2;
}

.testiMonial .slide-grid .gray-bg .object-4 {
  transform: translate(397px, -71px);
}

.testiMonial .slide-grid .blue-bg .heading-wrap {
  max-width: 290px;
  margin: 0 auto;
}

.testiMonial .slide-grid .blue-bg h4 {
  letter-spacing: -.025em;
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}

.testiMonial .slide-grid .blue-bg {
  background: url("testimonial.503bbf92.png") 0 0 / cover no-repeat;
  position: relative;
}

.testimonial-container {
  max-width: 1225px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  position: absolute;
  inset: 50% 0 0;
  transform: translateY(-66%);
}

.testimonial-container .slider, .carousel-item {
  letter-spacing: -.025em;
  color: #1d1d1d;
  width: 100%;
  font-size: 22px;
  font-weight: 300;
  line-height: 34px;
}

.testi-logo {
  transform: translateY(-13%);
}

.testimonial-container .client {
  max-width: 730px;
  margin-left: auto;
  margin-right: 0;
  position: relative;
}

.testiMonial .indicator-arrow {
  margin-top: 0;
  position: absolute;
  inset: auto 0 -95px -1px;
}

.testiMonial .indicator-arrow .arrows a {
  color: #0000;
}

.testiMonial .arrow-btn {
  width: 80px;
  justify-content: space-between;
  display: flex;
}

.testi-logo {
  width: 100px;
}

.bg-section1 {
  background-image: url("bg-section-1.b8a4c11e.png") !important;
  background-position: center !important;
  background-size: cover !important;
}

.as-seen-img {
  width: 10rem;
  height: 5rem;
  object-fit: contain;
  object-position: center;
  margin: .7rem;
}

.bg-testimonial {
  background-image: url("testimonial.503bbf92.png") !important;
  background-position: center !important;
  background-size: cover !important;
}

.process-card {
  background: #f2f2f2;
  border: none;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 0 25px #00000040;
}

.faq {
  border-top: 1px solid #d9d9d9;
}

.faq:last-child, .border-bottom {
  border-bottom: 1px solid #d9d9d9;
}

.ipad-seen {
  display: none;
}

.footer {
  background: linear-gradient(-45deg, #0047ff, #00237b, #020d3b, #0045b5) 0 0 / 400% 400%;
  padding: 60px 0;
  animation: 15s infinite gradient;
}

.footer .f-logo {
  width: 141px;
  margin-top: 4px;
}

.financial .footer-info {
  transform: none;
}

.footer .f-logo img {
  width: 100%;
}

.footer .footer-col-grid {
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr;
  padding-top: 10px;
  display: grid;
}

.footer .footer-info {
  color: #fff;
  text-shadow: 0 0 #ffffff03;
  max-width: 100%;
  max-width: 300px;
  width: 100%;
  font-size: 12px;
  font-weight: 300;
  line-height: 22px;
}

.footer .all-rights {
  color: #fff;
  margin-top: 0;
  font-size: 12px;
}

.footer .all-rights ul {
  grid-gap: 15px;
  grid-auto-flow: column;
  margin: 0;
  padding: 0;
  display: grid;
}

.footer .all-rights ul li {
  list-style: none;
}

.footer .all-rights ul li a {
  color: #fff;
  text-shadow: 0 0 #ffffff03;
  font-size: 12px;
  line-height: 24px;
  text-decoration: none;
}

.our-vision {
  height: 497px;
  background-image: url("about-bg-section-1.14028352.png");
  background-position: center;
  background-size: cover;
  align-items: center;
  margin-top: 33px;
  display: flex;
}

.our-vision .vision-content {
  max-width: 830px;
  margin: 0 auto;
  padding-bottom: 53px;
}

.our-vision h6 {
  text-align: center;
  letter-spacing: -.025em;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 64px;
  transform: translate(0, 24px);
}

.our-vision h2 {
  text-align: center;
  letter-spacing: -.025em;
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
}

.auditing-process {
  padding: 125px 0 112px;
}

.auditing-process h2 {
  letter-spacing: -.025em;
  color: #081e58;
  font-size: 28px;
  font-weight: 600;
  line-height: 64px;
}

.auditing-process p {
  letter-spacing: -.025em;
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.auditing-process .auditing-info {
  max-width: 490px;
}

.auditing-process .audit-grid {
  grid-gap: 42px 20px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-top: 51px;
  display: grid;
}

.auditing-process .audit-grid .card-body {
  padding: 27px 38px 50px;
}

.auditing-process .audit-grid .card-body h4 {
  letter-spacing: -.025em;
  color: #081e58;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
}

.auditing-process .audit-grid .card-body p {
  letter-spacing: -.025em;
  color: #1d1d1d;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.ready-smart {
  height: 374px;
  background-image: url("about-bg-section-2.ccbd9fd1.jpg");
  background-position: center;
  background-size: cover;
}

.ready-smart h2 {
  text-align: center;
  letter-spacing: -.025em;
  color: #fff;
  font-size: 54px;
  font-weight: 900;
  line-height: 72px;
}

.ready-smart .ready-content {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 37px;
  display: flex;
}

.about-page .about-banner, .faq-page .faq-banner, .contact-page .contact-banner {
  height: 316px;
  background-image: url("about-banner.977d362a.jpg");
  background-position: 0 0;
}

.contact-page .contact-banner {
  background-image: url("contact-banner.5005b76f.jpg");
}

.about-page .about-banner h1, .faq-page .faq-banner h1, .contact-page .contact-banner h1 {
  margin-bottom: 77px;
  font-size: 45px;
  font-weight: 800;
  line-height: 24px;
}

.about-page .about-tec-section {
  grid-gap: 156px;
  grid-template-columns: 1fr 37%;
  align-items: center;
  margin-top: 42px;
  display: grid;
}

.about-page .about-tec-section.reverse {
  grid-template-columns: 37% 1fr;
}

.about-page .about-tec-section.reverse .about-shape {
  max-width: 305px;
  transform: translate(97px, -39px);
}

.about-page .about-tec-section.reverse .tec-info {
  transform: translate(9px, -46px);
}

.about-page .about-tec-section .tec-info {
  transform: translate(0%, 23px);
}

.about-page .about-tec-section p {
  letter-spacing: -.025em;
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.about-page .about-tec-section .about-shape {
  max-width: 320px;
  width: 100%;
}

.about-page .about-tec-section .about-shape img {
  width: 100%;
}

.faq-content {
  margin-top: 100px;
  padding-bottom: 100px;
}

.faq-tile-grid {
  grid-gap: 30px;
  border-top: 1px solid #d9d9d9;
  grid-template-columns: 38% 1fr;
  padding: 41px 0;
  display: grid;
}

.brdr-btm {
  border-bottom: 1px solid #d9d9d9;
}

.faq-tile-grid h4 {
  letter-spacing: -.025em;
  color: #081e58;
  max-width: 340px;
  margin: 0;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
}

.faq-tile-grid p {
  letter-spacing: -.025em;
  color: #1d1d1d;
  margin: 0;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.faq-page .faq-contact-btn {
  margin: 70px 0 200px;
}

.contact-page .contact-grid {
  grid-gap: 30px;
  grid-template-columns: 38% 1fr;
  margin-top: 122px;
  display: grid;
}

.contact-page .contact-grid form {
  margin-top: 6px;
}

.contact-page .contact-grid h4 {
  letter-spacing: -.025em;
  color: #0047ff;
  max-width: 310px;
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
}

.contact-page .contact-grid p {
  letter-spacing: -.025em;
  color: #1d1d1d;
  max-width: 320px;
  margin-top: 13px;
  padding-top: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.contact-page .contact-grid .form-floating label {
  color: #081e58;
  font-size: 16px;
  top: -3px;
  left: 10px;
}

.contact-page .contact-grid .form-floating .form-control {
  height: 50px;
  background: #fff;
  border: 1px solid #081e58;
  border-radius: 100px;
  padding: 15px 8px 5px;
  line-height: normal;
  box-shadow: 0 1px 2px #0000000d;
}

.form-floating > .form-control:focus ~ label {
  transform: scale(.5)translateY(-.5rem)translateX(.15rem);
}

.contact-page .contact-grid form .btn.btn-primary {
  min-width: 236px;
  border-color: #081e58;
}

.contact-page .contact-grid .faq-contact-btn {
  margin: 28px 0 65px;
}

.legal-page {
  padding-bottom: 50px;
}

.legal-page .legal-banner {
  height: 298px;
  background-image: url("contact-banner.5005b76f.jpg");
  background-position: 0 0;
  background-size: cover;
  margin-bottom: 73px;
}

.legal-page h3 {
  letter-spacing: -.025em;
  color: #081e58;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 34px;
  padding-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
}

.legal-page .legal-banner h1 {
  margin-bottom: 100px;
  font-size: 30px;
  font-weight: 800;
  line-height: 30px;
}

.legal-page p {
  font-size: 14px;
  font-weight: normal;
}

.legal-page ol {
  counter-reset: item;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.legal-page ol li {
  counter-increment: item;
  color: #1d1d1d;
  margin-bottom: .6em;
  padding: 2px 0;
  font-size: 14px;
  display: table;
}

.legal-page ol li:before {
  content: counters(item, ".") ". ";
  padding-right: .6em;
  display: table-cell;
}

.legal-page li ol li {
  margin: 0;
}

.legal-page li ol > li:before {
  content: counters(item, ".") " ";
}

.fbold {
  font-weight: bold;
}

.legal-page ol ol ol {
  padding-left: 10px;
}

.legal-page ol ol.m {
  padding-left: 20px;
  list-style-type: lower-latin;
}

.legal-page ol ol.n {
  padding-left: 45px;
  list-style-type: lower-roman;
}

.legal-page ol ol.n li, .legal-page ol ul li {
  display: revert;
  counter-increment: auto;
}

.legal-page ol ol.n li:before, .legal-page ol ul li:before {
  display: none;
}

.legal-page ol ul {
  list-style-type: disc;
}

@media (max-width: 1366px) {
  .footer .footer-col-grid {
    justify-content: space-between;
    padding-top: 10px;
    display: flex;
  }

  .footer .dotcom {
    transform: none;
  }

  .financial .footer-info {
    max-width: 380px;
    padding-left: 30px;
    transform: none;
  }

  .footer .all-rights {
    flex-direction: column;
  }
}

@media (max-width: 1192px) {
  .seen-grid-1 img {
    width: 100%;
  }

  .testiMonial .slide-grid {
    grid-template-columns: 1fr 2fr;
  }

  .testimonial-container .slider, .carousel-item {
    letter-spacing: -.025em;
    color: #1d1d1d;
    width: 100%;
    font-weight: 300;
  }

  .home-page .start-audit-section .smart-grid .book-img {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .home-page .start-audit-section .smart-grid .book-img img {
    transform: none;
  }

  .testimonial-container .client {
    max-width: 100%;
  }

  .testiMonial .slide-grid {
    display: inherit;
    height: auto;
    background-color: #f2f2f2;
  }

  .testiMonial .indicator-arrow {
    margin-top: 50px;
    position: static;
  }

  .testiMonial .slide-grid .blue-bg {
    min-height: 400px;
  }

  .testimonial-container {
    padding: 50px 15px;
    position: static;
    transform: none;
  }
}

@media (max-width: 1024px) {
  .desktop-seen {
    display: none;
  }

  .ipad-seen {
    display: block;
  }

  .seen-in-section .seen-grid-1 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-auto-flow: inherit;
  }

  .seen-in-section .seen-grid-1 > div {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .seen-in-section .seen-grid-1 img {
    width: auto;
    max-width: 100%;
  }

  .home-page .start-audit-section {
    height: auto;
    padding: 100px 0;
  }

  .home-page .start-audit-section .object-1 {
    height: auto;
    position: absolute;
    transform: translate(5%, -10px);
  }

  .home-page .start-audit-section .smart-grid .book-img img {
    max-width: 250px;
    transform: none;
  }

  .home-page .start-audit-section .smart-grid {
    grid-gap: 20px;
    text-align: center;
    flex-flow: wrap-reverse;
    justify-content: center;
    display: flex;
  }

  .home-page .three-step-smart .wrapper .smart-img-grid .verif, .home-page .three-step-smart .wrapper .smart-img-grid .inden, .home-page .three-step-smart .wrapper .smart-img-grid .remedi, .home-page .three-step-smart .wrapper .smart-img-grid .remedi p, .home-page .three-step-smart .wrapper .smart-img-grid .verif p, .home-page .three-step-smart .wrapper .smart-img-grid .inden p, .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .inden p, .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .remedi, .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .remedi p, .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .inden, .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .verif, .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .verif p, .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid img, .home-page .three-step-smart.work-with-audit .audit-btn {
    flex-direction: column;
    justify-content: center;
    display: flex;
    transform: none;
    align-items: center !important;
  }

  .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid img {
    width: 140px;
    margin: 0 auto;
  }

  .home-page .three-step-smart {
    height: auto;
    padding: 100px 0;
  }

  .home-page .three-step-smart .wrapper .smart-img-grid, .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid {
    justify-content: space-around;
    display: flex;
  }

  .home-page .three-step-smart .wrapper .smart-img-grid p, .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid p {
    font-size: 18px;
  }

  .seen-in-section h2 {
    margin-top: 0;
  }
}

@media (max-width: 992px) {
  .about-page .about-tec-section {
    grid-gap: 50px;
  }

  .about-page .about-tec-section.reverse .about-shape {
    max-width: 250px;
    transform: none;
  }

  .about-page .about-tec-section.reverse .tec-info {
    transform: none;
  }

  .about-page .about-tec-section .about-shape {
    max-width: 250px;
  }
}

@media (max-width: 768px) {
  .nav-link.active {
    border-bottom: 1px solid #fff !important;
  }

  .object-1 {
    width: 98px;
    height: 193px;
    bottom: 20%;
  }

  .object-2 {
    width: 80px;
    height: 180px;
    top: 10%;
  }
}

@media (max-width: 767px) {
  .seen-in-section.desktop-seen, .seen-in-section.ipad-seen {
    display: none !important;
  }

  .logo {
    width: 130px;
  }

  #navbar {
    height: 50px;
    padding-bottom: 0;
  }

  .main-banner .object-2 {
    width: 91px;
  }

  .main-banner .object-1 {
    width: 100px;
    bottom: 44%;
  }

  .home-page .smart-thing-section .smart-grid {
    grid-template-columns: 1fr;
  }

  .home-page .smart-thing-section {
    min-height: 0;
    padding: 50px 15px;
  }

  .home-page .smart-thing-section h2 {
    max-width: 100%;
    font-size: 28px;
    line-height: 32px;
    transform: none;
  }

  .home-page .three-step-smart .wrapper .smart-img-grid, .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid {
    flex-direction: column;
  }

  .home-page .three-step-smart {
    padding: 30px 0;
  }

  .about-page .about-banner > div, .faq-page .faq-banner > div, .contact-page .contact-banner > div {
    align-items: center;
    justify-content: center !important;
  }

  .about-page .about-banner h1, .faq-page .faq-banner h1, .contact-page .contact-banner h1 {
    margin: 0;
  }

  .about-page .about-tec-section {
    grid-gap: 20px;
    flex-flow: wrap-reverse;
    display: flex;
  }

  .about-page .about-tec-section.reverse {
    flex-flow: column;
  }

  .about-page .about-tec-section .about-shape, .about-page .about-tec-section.reverse .about-shape {
    max-width: 220px;
    width: 100%;
    margin: 0 auto;
  }

  .about-page .about-tec-section .tec-info, .about-page .about-tec-section.reverse .about-shape, .about-page .about-tec-section.reverse .tec-info {
    transform: none;
  }

  .our-vision {
    height: auto;
  }

  .our-vision h2 {
    font-size: 24px;
    line-height: 42px;
  }

  .auditing-process h2 {
    font-size: 24px;
    line-height: 37px;
  }

  .auditing-process .audit-grid .card-body h4 {
    font-size: 20px;
  }

  .auditing-process .audit-grid {
    grid-gap: 20px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin-top: 20px;
    display: grid;
  }

  .auditing-process {
    padding: 45px 0 50px;
  }

  .auditing-process .audit-grid {
    margin-top: 30px;
  }

  .auditing-process .audit-grid .card-body {
    padding: 20px 20px 4px;
  }

  .ready-smart {
    height: auto;
    padding: 50px 0;
  }

  .ready-smart h2 {
    font-size: 34px;
    font-weight: 700;
  }

  .faq-tile-grid {
    grid-gap: 5px;
    grid-template-columns: 1fr;
    padding: 20px 0;
  }

  .faq-tile-grid h4 {
    font-size: 18px;
    line-height: 22px;
  }

  .faq-tile-grid p {
    padding-top: 0;
    font-size: 13px;
    line-height: 22px;
  }

  .contact-page .contact-grid h4 {
    font-size: 18px;
    line-height: 22px;
  }

  .contact-page .contact-grid p {
    padding-top: 0;
    font-size: 13px;
    line-height: 22px;
  }

  .faq-page .faq-contact-btn {
    margin: 35px 0;
  }

  .faq-page .btn.btn-primary {
    min-width: 190px;
  }

  .contact-page .contact-grid {
    grid-template-columns: 1fr;
    margin-top: 40px;
  }

  .contact-page .contact-grid h4, .contact-page .contact-grid p {
    max-width: 100%;
  }

  .legal-page .legal-banner > div {
    align-items: center;
    justify-content: center !important;
  }

  .legal-page .legal-banner h1 {
    margin: 0;
  }

  .footer .footer-col-grid {
    grid-gap: 10px;
  }

  .footer .all-rights {
    align-items: flex-start !important;
  }

  .testimonial-container .slider, .carousel-item {
    font-size: 18px;
    line-height: 30px;
  }
}

@media (max-width: 480px) {
  .testiMonial .indicator-arrow {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .testiMonial .indicator-arrow .arrows {
    margin-bottom: 30px;
  }

  .footer {
    padding: 30px 0;
  }

  .footer .footer-col-grid {
    padding-top: 0;
  }

  .financial .footer-info {
    padding-left: 0;
  }

  .footer .footer-col-grid, .footer .all-rights {
    flex-direction: column;
  }

  .footer .copyrights {
    transform: none;
  }
}

/*# sourceMappingURL=index.fa5ddb34.css.map */
