/* 
  Common styles
*/

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
li,
a,
blockquote,
pre,
hr,
figure,
fieldset,
legend,
textarea,
th,
td {
  font-family: "Work Sans", sans-serif;
}

body {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
   -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
       animation: fadein 1s;
       animation: fadein 1s;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.custom-container {
  max-width: 1226;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

.nav-link.active {
  font-weight: bold;
  border-bottom: 3px solid white;
}
header {
  padding-top: 60px;
}
.logo {
  width: 175px;;
}
#navbar {
  padding-bottom: 0px;
  z-index: 999;
  background: #081E58;
  transition: background-color 300ms ease;
  height: 80px;
  display: flex;
  align-items: center;
}
#navbar header {
  padding-top: 0px;
}
#navbar.transparent header {
  padding-top: 0px;
}
#nav-desktop a {
  margin-right: 25px;
  font-size: 15px;
  font-weight: 700;
  line-height: 40px;
}

.text-primary {
  color: #081e58 !important;
}

.bg-gradient {
  background: linear-gradient(257.98deg, #0047FF 9.06%, #030303 101.32%) !important;
}

.hero-bg {
  background-image: url("/src/assets/hero-bg.jpg");
  background-position: center;
  background-size: cover;
}

.nav-link.active {
  font-weight: bold;
  border-bottom: 3px solid white;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slide-in-from-left {
  animation: slideInFromLeft 0.5s ease-in-out;
}

@keyframes slideOutToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.slide-out-to-left {
  animation: slideOutToLeft 0.5s ease-in-out;
}

.mobile-nav {
  z-index: 99999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #081e58;
}

.text-small {
  font-size: 0.8rem;
}

.transparent {
  background-color: transparent !important;
  border: none !important;
}

.bg-primary {
  background-color: #081e58 !important;
}
.bg-black {
  background-color: #000 !important;
}
.bg-light {
  background-color: #f2f2f2 !important;
}

.btn.bg-black.btn-dark:hover {
  background-color: #fff !important;
  color: #000 !important;
}

.btn.bg-black.btn-dark:active {
  background-color: #fff !important;
  color: #000 !important;
  transform: translateY(1px);
}

.btn-primary {
  text-align: center;
  letter-spacing: -.025em;
  color: #fff;
  background: #000;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 400;
  line-height: 43px;
  height: 43px;
  padding: 0;
  min-width: 236px;
  border: 0;
}

.btn-primary:hover,
.btn:first-child:active,
.btn:focus-visible,
.btn:first-child:active:focus-visible{
  background-color: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  color: #030303;
  border: 0;
  box-shadow: none;
}

/* Homepage */

.main-banner {
  height: 563px;
}

.main-banner h1 {
  font-weight: 800;
  margin-top: 30px;
}
.main-banner .object-1 {
  width: 122px;
  z-index: 1;
  right: 0;
  bottom: 32%;
}

.main-banner .object-2 {
  width: 171px;
  height: auto;
  bottom: 0;
  top: 210px;
}
.home-page .start-audit-section {
  height: 563px;
}
.home-page .start-audit-section .wrapper {
  max-width: 984px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.home-page .start-audit-section .smart-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.home-page .start-audit-section .smart-grid .book-img img {
  max-width: 353px;
  transform: translate(146px, 122px);
}

.home-page .start-audit-section .object-1 {
  position: absolute;
  width: 200px;
  transform: translate(42%, -51%);
}
.home-page .start-audit-section .start-audit {
  position: relative;
  top: 10px;
}

.home-page .start-audit-section .smart-grid p {
  font-weight: 300;
  font-size: 22px;
  line-height: 34px;
  letter-spacing: -0.025em;
  color: #FFFFFF;
}

.home-page .smart-thing-section {
  min-height: 446px;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.home-page .smart-thing-section h2 {
  font-weight: 800;
  font-size: 48px;
  line-height: 54px;
  letter-spacing: -0.025em;
  color: #00237D;
  max-width: 330px;
  transform: translate(8px, 0px);
}

.home-page .smart-thing-section p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.025em;
  color: #1D1D1D;
}

.home-page .smart-thing-section .about-btn {
  transform: translate(2px, 14px);
}

.home-page .smart-thing-section .smart-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;  
}

.home-page .three-step-smart {
    background: url("/src/assets/bg-section-2.jpg") no-repeat center;
    background-size: cover;
    height: 497px;
}

.home-page .three-step-smart .wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.home-page .three-step-smart .wrapper h4 {
  font-weight: 700;
  font-size: 28px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.025em;
  color: #FFFFFF;
  transform: translate(0px, -25px);
}

.home-page .three-step-smart .wrapper .smart-img-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(120px, 120px));
  justify-content: space-between;
}

.home-page .three-step-smart .wrapper .smart-img-grid p {
  font-weight: 500;
  font-size: 24px;
  line-height: 54px;
  text-align: center;
  letter-spacing: -0.025em;
  color: #FFFFFF;
}

.home-page .three-step-smart .wrapper .smart-img-grid img {
  width: 120px;
}

.home-page .three-step-smart .wrapper .smart-img-grid .inden {
  transform: translate(5px, -11px);
}

.home-page .three-step-smart .wrapper .smart-img-grid .inden p {
  transform: translate(-12px, 4px);
}

.home-page .three-step-smart .wrapper .smart-img-grid .remedi {
  transform: translate(0px, -11px);
}

.home-page .three-step-smart .wrapper .smart-img-grid .remedi p {
  transform: translate(-12px, 4px);
}

.home-page .three-step-smart .wrapper .smart-img-grid .verif {
  transform: translate(12px, -9px);
}

.home-page .three-step-smart .wrapper .smart-img-grid .verif p {
  transform: translate(-8px, 4px);
}

.home-page .three-step-smart.work-with-audit {
  background: transparent;
}

.home-page .three-step-smart.work-with-audit .wrapper {
  max-width: 1100px;
}

.home-page .three-step-smart.work-with-audit h4 {
  color: #1D1D1D;
}

.home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  justify-content: space-between;
}

.home-page .three-step-smart.work-with-audit .wrapper h4 {
  transform: translate(0, -7px);
}

.home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid p {
  font-weight: 400;
  font-size: 20px;
  line-height: 54px;
  text-align: center;
  letter-spacing: -0.025em;
  color: #081E58;
  margin: 0;
}

.home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .inden p {
  transform: translate(-102px, 1px);
}

.home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .remedi p {
  transform: translate(-1px, 10px);
}

.home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .verif p {
  transform: translate(41px, 2px);
}

.home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid img {
  width: 140px;
}

.home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .inden {
  transform: translate(39px, 13px);
}

.home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .remedi {
  transform: translate(0, 4px);
}

.home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .verif {
  transform: translate(-37px, 9px);
}

.home-page .three-step-smart.work-with-audit .audit-btn {
  transform: translate(0px, -26px);
}

.seen-in-section .seen-grid-1 {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-rows: 80px;
  place-content: flex-start;
  grid-gap: 40px;
}
.seen-in-section h2 {
  margin-top: 70px;
  margin-bottom: 13px;
  font-weight: 700;
  font-size: 28px;
  line-height: 60px;
  letter-spacing: -0.025em;
  color: #081E58;
}

.testiMonial {
  margin-top: 72px;
}
.testiMonial .slide-grid {
  display: grid;
  grid-template-columns: 35% 1fr;
  height: 540px;
  position: relative;
}
.testiMonial .slide-grid .gray-bg {
  background: #F2F2F2;
}
.testiMonial .slide-grid .gray-bg .object-4 {
  transform: translate(397px, -71px);
}
.testiMonial .slide-grid .blue-bg .heading-wrap {
  max-width: 290px;
  margin: 0 auto;
}
.testiMonial .slide-grid .blue-bg h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.025em;
  color: #FFFFFF;
}
.testiMonial .slide-grid .blue-bg {
    background: url("/src/assets/testimonial.png") no-repeat;
    position: relative;
    background-size: cover;
}
.testimonial-container {
  max-width: 1225px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 50%;
  transform: translateY(-66%);
}
.testimonial-container .slider,
.carousel-item {
  letter-spacing: -.025em;
  color: #1d1d1d;
  font-weight: 300;
  width: 100%;
  font-size: 22px;
  font-weight: 300;
  line-height: 34px;
}
.testi-logo {
  transform: translateY(-13%);
}
.testimonial-container .client {
  max-width: 730px;
  position: relative;
  margin-right: 0;
  margin-left: auto;
}
.testiMonial .indicator-arrow {
  margin-top: 0;
  position: absolute;
  top: auto;
  left: -1px;
  right: 0;
  bottom: -95px;
}
.testiMonial .indicator-arrow .arrows a {
  color: transparent;
}
.testiMonial .arrow-btn {
  width: 80px;
  display: flex;
  justify-content: space-between;
}
.testi-logo {
  width: 100px;
}
.bg-section1 {
  background-image: url("/src/assets/bg-section-1.png") !important;
  background-position: center !important;
  background-size: cover !important;
}

.as-seen-img {
  width: 10rem;
  height: 5rem;
  object-fit: contain;
  object-position: center;
  margin: 0.7rem;
}

.bg-testimonial {
  background-image: url("/src/assets/testimonial.png") !important;
  background-position: center !important;
  background-size: cover !important;
}

.process-card {
  background: #f2f2f2;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px;
  border: none;
}

/* FAQs */

.faq {
  border-top: 1px solid #d9d9d9;
}
.faq:last-child {
  border-bottom: 1px solid #d9d9d9;
}


/* Contact */
.border-bottom {
  border-bottom: 1px solid #d9d9d9;
}

.ipad-seen {
  display: none;
}

.footer {
  padding: 60px 0;
  background: linear-gradient(-45deg,  #0047FF, #00237b, #020D3B, rgb(0, 69, 181));
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}
.footer .f-logo {
  width: 141px;
  margin-top: 4px;
}
.financial .footer-info {
  transform: none;
}
.footer .f-logo img {
  width: 100%;
}
.footer .footer-col-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  padding-top: 10px;
}
.footer .footer-info {
  font-size: 12px;
  line-height: 22px;
  color: #FFFFFF;
  text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.01);
  font-weight: 300;
  max-width: 100%;
  max-width: 300px;
  width: 100%;
}
.footer .all-rights {
  color: white;
  margin-top: 0;
  font-size: 12px;
}
.footer .all-rights ul {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 15px;
  padding: 0;
  margin: 0;
}
.footer .all-rights ul li {
  list-style: none;
}
.footer .all-rights ul li a {
  text-decoration: none;
  font-size: 12px;
  line-height: 24px;
  color: #FFFFFF;
  text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.01);
}

.our-vision {
  height: 497px;
  background-image: url("/src/assets/about-bg-section-1.png");
  background-position: center;
  background-size: cover;
  margin-top: 33px;
  display: flex;
  align-items: center;
}

.our-vision .vision-content {
  max-width: 830px;
  margin: 0 auto;
  padding-bottom: 53px;
}

.our-vision h6 {
  font-weight: 400;
  font-size: 16px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.025em;
  color: #FFFFFF;
  transform: translate(0px, 24px);
}

.our-vision h2 {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.025em;
  color: #FFFFFF;
}

.auditing-process {
  padding: 125px 0 112px 0px;
}

.auditing-process h2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 64px;
  letter-spacing: -0.025em;
  color: #081E58;
}

.auditing-process p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.025em;
  color: #1D1D1D;
}

.auditing-process .auditing-info {
  max-width: 490px;;
}

.auditing-process .audit-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 42px 20px;
  margin-top: 51px;
}

.auditing-process .audit-grid .card-body {
  padding: 27px 38px 50px 38px;
}

.auditing-process .audit-grid .card-body h4 {
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.025em;
  color: #081E58;
}

.auditing-process .audit-grid .card-body p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.025em;
  color: #1D1D1D;
}

.ready-smart {
  height: 374px;
  background-image: url("/src/assets/about-bg-section-2.jpg");
  background-position: center;
  background-size: cover;
}

.ready-smart h2 {
  font-weight: 900;
  font-size: 54px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -0.025em;
  color: #FFFFFF;
}

.ready-smart .ready-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding-top: 37px;
}

/* About */

.about-page .about-banner,
.faq-page .faq-banner,
.contact-page .contact-banner {
  height: 316px;
  background-image: url("/src/assets/about-banner.jpg");
  background-position: 0 0;
}

.contact-page .contact-banner {
  background-image: url("/src/assets/contact-banner.jpg");
}

.about-page .about-banner h1,
.faq-page .faq-banner h1,
.contact-page .contact-banner h1 {
  font-weight: 800;
  font-size: 45px;
  line-height: 24px;
  margin-bottom: 77px;
}

.about-page .about-tec-section {
  grid-template-columns: 1fr 37%;
  display: grid;
  align-items: center;
  margin-top: 42px;
  grid-gap: 156px;
}

.about-page .about-tec-section.reverse {
  grid-template-columns: 37% 1fr;
}

.about-page .about-tec-section.reverse .about-shape {
  transform: translate(97px, -39px);
  max-width: 305px;
}

.about-page .about-tec-section.reverse .tec-info {
  transform: translate(9px, -46px);
}

.about-page .about-tec-section .tec-info {
  transform: translate(0%, 23px);
}

.about-page .about-tec-section p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.025em;
  color: #1D1D1D;
}

.about-page .about-tec-section .about-shape {
  max-width: 320px;
  width: 100%;
}

.about-page .about-tec-section .about-shape img {
  width: 100%;
}

.faq-content {
  margin-top: 100px;
  padding-bottom: 100px;
}

.faq-tile-grid {
  grid-gap: 30px;
  border-top: 1px solid #d9d9d9;
  grid-template-columns: 38% 1fr;
  padding: 41px 0;
  display: grid;
}
.brdr-btm {
  border-bottom: 1px solid #d9d9d9;
}
.faq-tile-grid h4 {
  letter-spacing: -.025em;
  color: #081E58;
  margin: 0;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  max-width: 340px;
}
.faq-tile-grid p {
  letter-spacing: -.025em;
  color: #1d1d1d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  padding-top: 5px;
}
.faq-page .faq-contact-btn {
  margin: 70px 0 200px 0;
}

.contact-page .contact-grid {
  display: grid;
  grid-template-columns: 38% 1fr;
  grid-gap: 30px;
  margin-top: 122px;
}
.contact-page .contact-grid form {
  margin-top: 6px;
}
.contact-page .contact-grid h4 {
  letter-spacing: -.025em;
  color: #0047ff;
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  max-width: 310px;
  line-height: 31px;
}
.contact-page .contact-grid p {
  letter-spacing: -.025em;
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  padding-top: 5px;
  margin-top: 13px;
  max-width: 320px;
}
.contact-page .contact-grid .form-floating label {
  font-size: 16px;
  color: #081E58;
  top: -3px;
  left: 10px;
}
.contact-page .contact-grid .form-floating .form-control {
  height: 50px;
  line-height: normal;
  padding: 15px 8px 5px 8px;
  border-color: #081E58;
  background: #FFFFFF;
  border: 1px solid #081E58;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
}
.form-floating>.form-control:focus~label {
  transform: scale(.50) translateY(-0.5rem) translateX(0.15rem);
}
.contact-page .contact-grid form .btn.btn-primary {
  border-color: #081E58;
  min-width: 236px;
}
.contact-page .contact-grid .faq-contact-btn {
  margin: 28px 0 65px;
}

.legal-page {
  padding-bottom: 50px;
}

.legal-page .legal-banner {
  height: 298px;
  background-image: url("/src/assets/contact-banner.jpg");
  background-position: 0 0;
  background-size: cover;
  margin-bottom: 73px;
}

.legal-page h3 {
  letter-spacing: -.025em;
  color: #081E58;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 34px;
  padding-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
}

.legal-page .legal-banner h1 {
  font-weight: 800;
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 100px;
}

.legal-page p {
  font-size: 14px;
  font-weight: normal;
}
.legal-page ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0px;
}

.legal-page ol li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
  color: #1D1D1D;
  padding: 2px 0 ;
  font-size: 14px;
}

.legal-page ol li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;    
}

.legal-page li ol li {
  margin: 0;
}

.legal-page li ol > li:before {
  content: counters(item, ".") " ";
}
.fbold {
  font-weight: bold;
}
.legal-page ol ol ol {
  padding-left: 10px;
}
.legal-page ol ol.m {
  list-style-type: lower-latin;
  padding-left: 20px;
}
.legal-page ol ol.n {
  list-style-type: lower-roman;
  padding-left: 45px;
}
.legal-page ol ol.n li,
.legal-page ol ul li {
  display: revert;
  counter-increment: auto;
}
.legal-page ol ol.n li:before,
.legal-page ol ul li:before {
  display: none;
}
.legal-page ol ul {
  list-style-type: disc;
}

/* Responsive Start */

@media (max-width: 1366px) {
  .footer .footer-col-grid {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .footer .dotcom {
    transform: none;
  }
  .financial .footer-info {
    max-width: 380px;
    transform: none;
    padding-left: 30px;
  }
  .footer .all-rights {
    flex-direction: column;
  }
}


@media (max-width: 1192px) { 
  .seen-grid-1 img {
    width: 100%;
  }
  .testiMonial .slide-grid {
    grid-template-columns: 1fr 2fr;
  }
  .testimonial-container .slider, .carousel-item {
    letter-spacing: -.025em;
    color: #1d1d1d;
    width: 100%;
    font-weight: 300;
  }
  .home-page .start-audit-section .smart-grid .book-img {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .home-page .start-audit-section .smart-grid .book-img img {
    transform: none;
  }
  .testimonial-container .client {
    max-width: 100%;
  }
  .testiMonial .slide-grid {
    display: inherit;
    background-color: #f2f2f2;
    height: auto;
  }
  .testiMonial .indicator-arrow {
    position: static;
    margin-top: 50px;
  }
  .testiMonial .slide-grid .blue-bg {
    min-height: 400px;
  }
  .testimonial-container {
    position: static;
    transform: none;
    padding: 50px 15px;
  }
}

@media (max-width: 1024px) { 
  .desktop-seen {
    display: none;
  }
  .ipad-seen {
    display: block;
  }
  .seen-in-section .seen-grid-1 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-auto-flow: inherit;
  }
  .seen-in-section .seen-grid-1 > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .seen-in-section .seen-grid-1 img {
    width: auto;
    max-width: 100%;
  }
  .home-page .start-audit-section {
    height: auto;
    padding: 100px 0px;
  }
  .home-page .start-audit-section .object-1 {
    position: absolute;
    transform: translate(5%, -10px);
    height: auto;
  }
  .home-page .start-audit-section .smart-grid .book-img img {
    transform: none;
    max-width: 250px;
  }
  .home-page .start-audit-section .smart-grid {
    grid-gap: 20px;
    display: flex;
    flex-direction: column;
    flex-flow: wrap-reverse;
    justify-content: center;
    text-align: center;
  }
  .home-page .three-step-smart .wrapper .smart-img-grid .verif,
  .home-page .three-step-smart .wrapper .smart-img-grid .inden,
  .home-page .three-step-smart .wrapper .smart-img-grid .remedi,
  .home-page .three-step-smart .wrapper .smart-img-grid .remedi p,
  .home-page .three-step-smart .wrapper .smart-img-grid .verif p,
  .home-page .three-step-smart .wrapper .smart-img-grid .inden p,
  .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .inden p,
  .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .remedi,
  .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .remedi p,
  .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .inden,
  .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .verif,
  .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid .verif p,
  .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid img,
  .home-page .three-step-smart.work-with-audit .audit-btn {
    transform: none;
    align-items: center !important;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid img {
    width: 140px;
    margin: 0 auto;
  }
  .home-page .three-step-smart {
    height: auto;
    padding: 100px 0;
  }
  .home-page .three-step-smart .wrapper .smart-img-grid,
  .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid {
    display: flex;
    justify-content: space-around;
  }
  .home-page .three-step-smart .wrapper .smart-img-grid p,
  .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid p {
    font-size: 18px;
  }
  .seen-in-section h2 {
    margin-top: 0;
  }
}

@media (max-width: 992px) {
  .about-page .about-tec-section {
    grid-gap: 50px;
  }
  .about-page .about-tec-section.reverse .about-shape {
    max-width: 250px;
    transform: none;
  }
  .about-page .about-tec-section.reverse .tec-info {
    transform: none;
  }
  .about-page .about-tec-section .about-shape {
    max-width: 250px;
  }
}

@media (max-width: 768px) {
  .nav-link.active {
    border-bottom: 1px solid white !important;
  }
  .object-1 {
    width: 98px;
    height: 193px;
    bottom: 20%;
  }
  .object-2 {
    width: 80px;
    height: 180px;
    top: 10%;
  }
}

@media (max-width: 767px) {
  .seen-in-section.desktop-seen,
  .seen-in-section.ipad-seen {
    display: none !important;
  }
  .logo {
    width: 130px;
  }
  #navbar {
    padding-bottom: 0px;
    height: 50px;
  }
  .main-banner .object-2 {
    width: 91px;
  }
  .main-banner .object-1 {
    width: 100px;
    bottom: 44%;
  }
  .home-page .smart-thing-section .smart-grid {
    grid-template-columns: 1fr;
  }
  .home-page .smart-thing-section {
    min-height: 0;
    padding: 50px 15px
  }
  .home-page .smart-thing-section h2 {
    max-width: 100%;
    font-size: 28px;
    line-height: 32px;
    transform: none;
  }
  .home-page .three-step-smart .wrapper .smart-img-grid,
  .home-page .three-step-smart.work-with-audit .wrapper .smart-img-grid {
    flex-direction: column;
  }
  .home-page .three-step-smart {
    padding: 30px 0;
  }
  .about-page .about-banner > div,
  .faq-page .faq-banner > div,
  .contact-page .contact-banner > div {
    justify-content: center !important;
    align-items: center;
  }
  .about-page .about-banner h1,
  .faq-page .faq-banner h1,
  .contact-page .contact-banner h1 {
    margin: 0;
  }
  .about-page .about-tec-section {
    display: flex;
    flex-direction: column;
    flex-flow: wrap-reverse;
    grid-gap: 20px;
  }
  .about-page .about-tec-section.reverse {
    flex-flow: column;
  }
  .about-page .about-tec-section .about-shape,
  .about-page .about-tec-section.reverse .about-shape {
    margin: 0 auto;
    max-width: 220px;
    width: 100%;
  }
  .about-page .about-tec-section .tec-info,
  .about-page .about-tec-section.reverse .about-shape,
  .about-page .about-tec-section.reverse .tec-info {
    transform: none
  }
  .our-vision {
    height: auto;
  }
  .our-vision h2 {
    font-size: 24px;
    line-height: 42px;
  }
  .auditing-process h2 {
    font-size: 24px;
    line-height: 37px;
  }
  .auditing-process .audit-grid .card-body h4 {
    font-size: 20px;
  }
  .auditing-process .audit-grid {
    grid-gap: 20px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin-top: 20px;
    display: grid;
  }
  .auditing-process {
    padding: 45px 0 50px;
  }
  .auditing-process .audit-grid {
    margin-top: 30px;
  }
  .auditing-process .audit-grid .card-body {
    padding: 20px 20px 4px;
  }
  .ready-smart {
    height: auto;
    padding: 50px 0;
  }
  .ready-smart h2 {
    font-size: 34px;
    font-weight: 700;
  }
  .faq-tile-grid {
    padding: 20px 0;
  }
  .faq-tile-grid {
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }
  .faq-tile-grid h4 {
    font-size: 18px;
    line-height: 22px;
  }
  .faq-tile-grid p {
    padding-top: 0px;
    font-size: 13px;
    line-height: 22px;
  }
  .contact-page .contact-grid h4 {
    font-size: 18px;
    line-height: 22px;
  }
  .contact-page .contact-grid p {
    padding-top: 0px;
    font-size: 13px;
    line-height: 22px;
  }
  .faq-page .faq-contact-btn {
    margin: 35px 0 35px;
  }
  .faq-page .btn.btn-primary {
    min-width: 190px;
  }
  .contact-page .contact-grid {
    grid-template-columns: 1fr;
    margin-top: 40px;
  }
  .contact-page .contact-grid h4,
  .contact-page .contact-grid p {
    max-width: 100%;
  }
  .legal-page .legal-banner > div {
    justify-content: center !important;
    align-items: center;
  }
  .legal-page .legal-banner h1 {
    margin: 0;
  }
  .footer .footer-col-grid {
    grid-gap: 10px;
  }
  .footer .all-rights {
    align-items: flex-start !important;
  }
  .testimonial-container .slider,
  .carousel-item {
    font-size: 18px;
    line-height: 30px;
  }
}


@media (max-width: 480px) {
.testiMonial .indicator-arrow {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .testiMonial .indicator-arrow .arrows {
    margin-bottom: 30px;
  }
  .footer {
    padding: 30px 0;
  }
  .footer .footer-col-grid {
    padding-top: 0px;
  }
  .financial .footer-info {
    padding-left: 0;
  }
  .footer .footer-col-grid,
  .footer .all-rights {
    flex-direction: column;
  }
  .footer .copyrights {
    transform: none;
  }
}
